export function updateMetadata(state, metadata) {
  state.metadata = { ...state.metadata, ...metadata };
}

export function updateMetadataLoadingState(state, isLoading) {
  state.metadata = {
    ...state.metadata,
    loading: isLoading,
  };
}

export function updateMetadataErrorState(state, error) {
  state.metadata = {
    ...state.metadata,
    error,
  };
}

export function updateAddProductLoadingState(state, isLoading) {
  state.newProduct = {
    ...state.newProduct,
    loading: isLoading,
  };
}

export function updateAddProductErrorState(state, error) {
  state.newProduct = {
    ...state.newProduct,
    error,
  };
}

export function updateProductsList(state, response) {
  state.currentProducts.productList = response.products;
  state.currentProducts.metadata = response.metadata;
}
export function updateProductWithRelatedProducts(state, response) {
  const product = state.currentProducts.productList.findIndex((item) => item.id === response.id);
  state.currentProducts.productList[product] = response;
}

export function addProducts(state, response) {
  state.currentProducts.productList.push(...response.products);
  state.currentProducts.metadata = response.metadata;
}

export function updateAvailableProducts(state, response) {
  state.availableProducts.productList = response.products;
  state.availableProducts.metadata = response.metadata;
}

export function updateProductsLoading(state, response) {
  state.loading = response;
}
export function updateAvailableProductsLoading(state, loading) {
  state.availableProducts.loading = loading;
}

export function updateProductDetails(state, response) {
  state.productDetails = {
    ...state.productDetails,
    data: {
      ...response,
      extras: response.extras.map((extra) => ({ ...extra, applyMinMax: extra.min !== null || extra.max !== null })),
    },
  };
}

export function updateProductDetailsStatus(state, status) {
  state.productDetails.data = {
    ...state.productDetails.data,
    status,
    extras: state.productDetails.data.extras.map((extra) => ({
      ...extra,
      applyMinMax: extra.min !== null || extra.max !== null,
    })),
  };
}

export function updateProductDetailsLoading(state, isLoading) {
  state.productDetails = {
    ...state.productDetails,
    loading: isLoading,
  };
}

export function updateProductDetailsError(state, error) {
  state.productDetails = {
    ...state.productDetails,
    error,
  };
}

export function resetProductDetails(state) {
  state.productDetails = {
    loading: false,
    error: null,
    data: null,
  };
}

export function updateProductsOutOfStock(state, count) {
  state.currentProducts.outOfStockCount = count;
}

export function updateExportLoading(state, loading) {
  state.exportLoading = loading;
}

export function updateImportLoading(state, loading) {
  state.importLoading = loading;
}

export function updateInstagramToken(state, token) {
  state.instagram.token = token;
}

export function updateInstagramPosts(state, posts) {
  state.instagram.posts = posts;
}

export function updateInstagramLoading(state, loading) {
  state.instagram.loading = loading;
}
